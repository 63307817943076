Vue.component('carItemComponent',{
    props:['itemCode'],
    data: function (){
        return {
            currentValue:0
        };
    },
    watch: {
        "priceItem": function () {
            this.$store.dispatch('updateCarAmount');
        },
        "carCant": function () {
            this.$store.dispatch('updateCarAmount');
        }

    },
    computed:{
        item(){
            return this.$store.getters.getItemByCode(this.itemCode);
        },
        priceItem(){
            let storeItem = this.$store.getters.getItemByCode(this.itemCode);
            //console.log('Items in Car',this.itemCode,storeItem);
            if(storeItem)
                return this.$store.getters.showItemPrice(storeItem);
            else
                return 0;
        },
        carCant(){
            // eslint-disable-next-line
            this.currentValue = this.$store.state.items_in_map.get(this.itemCode).cant;
            return this.$store.state.items_in_map.get(this.itemCode).cant;
        }
    },
    methods:{
        itemQtyIncrement: function (){
            let self = this;
            this.$store.dispatch('changeCartQty',{itemCode:this.itemCode,action:'up'}).then(()=>{
                self.saveCarSession();
            });
        },
        itemQtyDecrement: function (){
            let self = this;
            this.$store.dispatch('changeCartQty',{itemCode:this.itemCode,action:'down'}).then(()=>{
                self.saveCarSession();
            });
        },
        itemQtyChange: function (){
            let self = this;
            this.$store.dispatch('changeCartQty',{itemCode:this.itemCode,action:'update',itemQty:this.currentValue}).then(()=>{
                self.saveCarSession();
            });
        },
        deleteItemFromCar:function (){
            let self = this;
            this.$store.dispatch('changeCartQty',{itemCode:this.itemCode,action:'delete'}).then(()=>{
                self.saveCarSession();
            });
        },
        saveCarSession:function (force = false){
            // eslint-disable-next-line
            axios.post('/ecommerce/api/saveCarSession', {'items':JSON.stringify(this.$store.getters.getItemCar)});
        }
    },
    template:` <div class="col m12 produktliste cart-items-list" :title="'Precio Unitario '+priceItem">
                    <div class="col m5 s12 item-title">
                        <a class="truncate" :title="item.Name">{{item.Name}}</a>
                    </div>
                    <div class="col m7 s12 item-qty-section">
                       <div class="carControl">		    
                            <span @click="itemQtyIncrement()"><i class="fas fa-angle-up waves-effect" ></i></span>
                            <input class="input-item-amount" type="text" v-model="currentValue" @change="itemQtyChange">
                            <span @click="itemQtyDecrement()"><i class="fas fa-angle-down waves-effect" ></i></span>
                            <span @click="deleteItemFromCar()" ><i class="far fa-trash-alt" ></i></span>
                        </div>                                              
                    </div>
                </div>`
});

Vue.component('carComponent', {
    props:  ['is_side_nav'],
    watch:{
        '$store.state.total_in_car_iva':function (){
            // eslint-disable-next-line
            console.log('running watch');
        }
    },
    methods:{
        itemQtyIncrement:function (itemCode){
            this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'up'});
        },
        itemQtyDecrement:function (itemCode){
            this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'down'});
        },
        deleteItemFromCar:function (itemCode){
            this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'delete'});
        },
        checkPrice: function (){
            let itemWithPrices = [];
            for(let carItem of this.itemsSelected) {
                let storeItem = this.$store.getters.getItemByCode(carItem.id);
                if (!storeItem.isCalculate)
                    itemWithPrices.push(storeItem);
            }
            if(itemWithPrices.length > 0) {
                // eslint-disable-next-line
                console.log('Loading Prices');
                this.$store.dispatch('updatePrices', itemWithPrices);
                this.$store.dispatch('updateCarAmount');
            }
        },
        showPriceLabel: function () {
            if(this.$store.getters.getPriceToShow)
                return '(IVA INC)';
            else{
                return '(IVA EXC)';
            }
        },
        viewOrder: function () {
            this.$store.dispatch('updateItemToOrder');
            let url = '/orderdetail/' + this.encode(this.$store.getters.getCurrentOrder.fields.SerNr);
            if(this.$router.currentRoute.fullPath != url){
                this.$router.push('/orderdetail/' + this.encode(this.$store.getters.getCurrentOrder.fields.SerNr));
            }
        },
        clearBacket: function (){
            this.$store.dispatch('clearItemToCar','view');
            this.$store.dispatch('saveCarSession');

        },
        saveCarSession:function (force = false){
            if(!force && this.$store.getters.getItemCar.length == 0)
                return null;
            // eslint-disable-next-line
            axios.post('/ecommerce/api/saveCarSession', {'items':JSON.stringify(this.$store.getters.getItemCar)});
        },
        getCarLink:function (){
            if(this.checkoutEnabled)
                return "/badges";
            return this.$route.fullPath;
        }
    },
    computed:{
        totalAmountIVA(){
            return this.$store.getters.getCarAmountIVA;
        },
        totalAmount(){
            return this.$store.getters.getCarAmount;
        },
        status(){
            return this.$store.getters.getCarStatus;
        },
        itemsSelected:function (){
            return this.$store.getters.getItemCar;

        },
        mustSendCarItems(){
            if(this.itemsSelected.length > 0 || this.totalAmount || this.totalAmountIVA) {
                if (this.checkoutEnabled)
                    this.saveCarSession();
            }
            return true;
        },
        checkoutEnabled(){
            if(this.$store.getters.getSession == null)
                return false;
            return true;
        }
    },
    mounted:function (){
        this.checkPrice();
    },
    updated:function (){
        //this.saveCarSession()
    },
    template:
            `<div>
                 <div class="card-panel cart-section row">
                     <router-link v-bind:to="getCarLink()">
                          <div class="col 12 m3 s12">
                            <img src="../static/ecommerce/assets/img/sho.jpg" width="40" />
                            <div class="gallery-action">
                                <a class="go-to-ratings btn-floating btn-large waves-effect waves-light active" >{{$store.getters.getItemCarCount}} </a>
                            </div>
                          </div>
                          <div class="col 12 m8 s12">
                              <h5 class="white-text">MI CARRITO<br>
                              <span class="white-text">{{$store.getters.getItemCarCount}} Artículos</span></h5>
                              <!-- Dropdown Structure -->
                          </div>
                     </router-link>
                 </div>
                 <div class="card-panel cart-items-section row">
                    <div class-="col m12">
                        <a class="btn clear-basket col m12 s12" @click="clearBacket">{{tr("Clear Basket")}}</a>
                   </div>
                    <template  v-for="item in itemsSelected">
                       <carItemComponent :itemCode="item.id"></carItemComponent>
                    </template>
                    <div class="row produktliste total-amount">
                       <div class="col s12">
                            <strong><a>TOTAL</a></strong>
                       </div>
                       <div class="col s12 right-align">
                            <strong><a> $ {{totalAmount}} </a></strong>
                        </div>
                    </div>
                    <div class="row produktliste total-amount">
                       <div class="col s12">
                            <strong><a>TOTAL (IVA INC)</a></strong>
                       </div>
                       <div class="col s12 right-align">
                            <strong><a> $ {{totalAmountIVA}} </a></strong>
                        </div>
                    </div>
                    <div class="row produktliste" v-if="checkoutEnabled">                         
                        <div>
                            <template v-if="$store.getters.getCarStatus!='editOrder'">
                                <a class="btn checkout-btn col m12 s12" @click="$router.push('/badges');">{{tr("Checkout")}}</a>
                            </template>
                            <template v-else>
                                <a class="btn checkout-btn col m12 s12" @click="viewOrder()">{{tr("Add to Order")}}</a>
                            </template>
                        </div>  
                    </div>
                </div>
            </div>`
});
